import { useEffect, useState } from "react"
import GoogleLogin from "react-google-login"
import { gapi } from 'gapi-script'
import styled from "styled-components"

import { useNavigate } from "react-router-dom"

// dispatch
import { useDispatch } from "react-redux"
import { login } from "../redux/userDucks"


const StyledGoogleLogin = styled(GoogleLogin)`
    font-family: 'Montserrat' !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem !important;
    background-color: #f1f1f1 !important;
    border: none !important;
    padding: .8rem 2rem !important;
    border-radius: 20px !important;
    font-size: 13px !important; 
    font-weight: 300 !important;

    div:first-child {
        padding: 0 !important;
        margin: 0 !important;
        background-color: transparent !important;
        svg {
            width: 100% !important;
        }
    }
    span {
        padding: 0 !important;
        color: black !important;
        font-weight: 400 !important;
    }
`

const GoogleLoginComponent = ({currentRoute}) => {

    const [buttonText, setButtonText] = useState('')

    const navigate = useNavigate()

    const dispatch = useDispatch()

    //HABILITAR ESTE CLIENTID CUANDO ESTE EN DESARROLLO.
     const clientId = '690823194345-rnh0ukdajsuaiak57jm9v9hg40p3jq4o.apps.googleusercontent.com' 

    //HABILITAR ESTE CLIENTID CUANDO SE PASE AL SERVIDOR.
   // const clientId = '690823194345-e7lbtaih30vng4n821jdnlcqfe14alsg.apps.googleusercontent.com' 

    useEffect(() => {
        gapi.load('auth2', () => {
            gapi.auth2.init({
                clientId
            })
        })
        //eslint-disable-next-line
    }, [])


    useEffect(() => {
        if(currentRoute === 'login'){
            setButtonText('Continuar con Google')
            return
        }
        setButtonText('Registrarme con Google')
    }, [currentRoute])

    const onSuccess = (response) => {
        const obj = {
            mail: response.profileObj.email,
            userId: response.profileObj.googleId,
            userName: response.profileObj.name.split(' ')[0],
            userLastName: response.profileObj.familyName.split(' ')[0],
            profilePhoto: response.profileObj.imageUrl,
            membership: false,
            telephone: null,
            successMessage: 'Inicio de sesión correcto'
        }

        

        dispatch(login(obj))
        navigate('/inicio')
    }

    const onFailure = response => {
        console.log(response)
    }

    return (
        <>
            <StyledGoogleLogin 
                clientId={process.env.REACT_APP_ID_GOOGLE}
                buttonText={buttonText}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_policy'}
            />
        </>
    )
}

export default GoogleLoginComponent