import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import RegisterImage from '../assets/login-registro/imagenes/Unirme.jpg'
import LoginImage from '../assets/login-registro/imagenes/Entrar.jpg'
import Isotipo from '../assets/login-registro/iconos/00-isotipo.svg'
// import AppleIcon from '../assets/login-registro/iconos/01-apple.svg'
// import FacebookIcon from '../assets/login-registro/iconos/FB.svg'
import OjoIcon from '../assets/login-registro/iconos/03-ojo.svg'
import CustomButton from '../components/CustomButton';

import { useForm } from '../hooks/useForm'
import { validateRegisterValues } from '../utils'
import { cleanMessages, cleanStatus, creatingUser, generateNewToken, loginUser, sendActivateCode } from '../redux/userDucks'
import Alert from '../components/Alert'
import Spinner from '../components/spinner/Spinner'
import GoogleLoginComponent from '../components/GoogleLogin'
import FacebookLoginComponent from '../components/FacebookLogin'

const Container = styled.div`
    height: 100vh;
    display: flex;
`

const LeftSide = styled.div`
    flex: 1;
    display: none;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    @media (min-width: 992px) {
        display: block;
    }
`

const RightSide = styled.div`
    flex: 1;
    background: rgb(57,157,255);
    background: linear-gradient(90deg, rgba(57,157,255,1) 0%, rgba(87,134,255,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
`

const CardWrapper = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 25px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    img {
        width: 100px;
    }

    .separator {
        color: gray;
        font-weight: 300;
        margin: 0;
    }

    .auth-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        
        .login, .register {
            color: gray;
            font-weight: 300;
        }

        .login.active, .register.active {
            color: #5786FF; 
            font-weight: 500;
        }
    }

    .seven-days, .dual-membership {
        color: #63A355;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
`

const SocialSignUpWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;


    .apple, .google {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: #f1f1f1;
        border: none;
        padding: .8rem 2rem;
        border-radius: 20px;
        font-size: 13px;
        font-weight: 400;
    }

    .apple img, .google img {
        width: 20px;
    }

    .apple:hover, .google:hover {
        cursor: pointer;
    }
`
const FormWrapper = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .password-container {
        width: 100%;
        display: flex;
        padding: 0 2rem;
        background-color: #f1f1f1;
        border-radius: 20px;
        gap: 1rem;
        align-items: center;

        input {
            border: none;
            flex: 2;
            outline: none;
            background-color: inherit;
            padding: .8rem 0;
        }

        img {
            width: 25px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    input {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: #f1f1f1;
        border: none;
        padding: .8rem 2rem;
        border-radius: 20px;
        font-size: 13px;
        font-weight: 400;
        outline: none;

        &::placeholder {
            font-weight: bold;
        }
    }

    .suscribirte, .olvide-password {
        font-size: 10px;
        margin: 0;
    }

    .suscribirte span {
        color: #5786ff;
        &:hover {
            cursor: pointer;
        }
    }

`

const ErrorMessage = styled.p`
    font-size: 13px;
    text-align: center;
    color: red;
    font-weight: 700;
    margin: 0;
` 

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Input = styled.input`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background-color: #f1f1f1;
        border: none;
        padding: .8rem 2rem;
        border-radius: 20px;
        font-size: 13px;
        font-weight: 400;
        outline: none;

        &::placeholder {
            font-weight: bold;
        }
`

const SendButtonCode = styled.button`
         background: rgb(57,157,223);
        background: linear-gradient(90deg, rgba(57,157,223,1) 0%, rgba(87,134,255,1) 100%);
        border: none;
        color: white;
        padding: .5rem 3rem;
        font-size: 1.3rem;
        -webkit-box-shadow: 0px 0px 17px -5px rgba(87,134,255,1);
        -moz-box-shadow: 0px 0px 17px -5px rgba(87,134,255,1);
        box-shadow: 0px 0px 17px -5px rgba(87,134,255,1);
        font-weight: 400;
        cursor: pointer;
`

const AuthPage = () => {

    const [activeButton, setActiveButton] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [values, setValues] = useState({})
    const [currentRoute, setCurrentRoute] = useState('')

    // code verification
    const [token, setToken] = useState('')
    const [isVerified, setIsVerified] = useState(false)

    const location = useLocation()
    const pathname = location.pathname

    const navigate = useNavigate()

    const { errorMessage, successMessage, status, sevenDayTrial, dualMembership, verified, userId, mail } = useSelector(state => state.userInfo)

    const dispatch = useDispatch()

    const handleActiveButton = buttonId => {
        if(activeButton === buttonId){
            return
        }
        setActiveButton(buttonId)
        dispatch(cleanStatus())
    }

    const handlePasswordVisible = () => {
        setIsPasswordVisible(!isPasswordVisible)
    }

    // send code
    const handleSendCode = () => {
        console.log('Enviando...')
        const obj = {
            token,
            email: mail,
            userId 
        }
        if(token && userId && mail){
            dispatch(sendActivateCode(obj))
        }
    }

    useEffect(() => {
        const checkRoutePathname = () => {
            if(pathname === '/login'){
                setActiveButton('button1')
                setValues({
                    email: '',
                    password: ''
                })
                setCurrentRoute('login')
            } else if (pathname === '/register'){
                setActiveButton('button2')
                setValues({
                    email: '',
                    password: '',
                    name: '',
                    lastname: ''
                })
                setCurrentRoute('register')
            }
        }

        checkRoutePathname()
    }, [pathname, dispatch])

    useEffect(() => {
        dispatch(cleanMessages())
    }, [pathname, dispatch])

    useEffect(() => {
        dispatch(cleanStatus())
    }, [dispatch])

    const { formValues, formErrors, handleChange, handleSuccess } = useForm(values, validateRegisterValues, pathname)

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(cleanMessages())
        if(handleSuccess()){
            if(currentRoute === 'login'){
                // console.log(formValues)
                dispatch(loginUser({...formValues, type: 'normal'}))
                return
            }
            // console.log(formValues)
            dispatch(creatingUser({...formValues, phone: null, type: 'normal'}))
        }
    }

    const generateNewCode = () => {
        dispatch(generateNewToken(userId))
    }

    useEffect(() => {
        if(status === 'authenticated' && verified) {
            navigate('/inicio')
        }
    }, [status, navigate, verified])

    return (
        <Container>
            <LeftSide>
                <img src={pathname === '/register' ? RegisterImage : LoginImage} alt="Unirme" />
            </LeftSide>        
            <RightSide>
                <CardWrapper>
                    <Link to="/" >
                        <img src={Isotipo} alt="logo" />
                    </Link>
                    { dualMembership && <p className='dual-membership'>Bienvenido. Tu registro incluye 2 membresías al precio de 1</p> }
                    { sevenDayTrial && <p className='seven-days'>Bienvenido. Tu registro incluye una prueba gratuita de 7 días</p>}
                    <div className='auth-buttons'>
                        <Link to="/login" className={`login ${activeButton === 'button1' ? 'active' : ''}`} onClick={() => handleActiveButton('button1')} >Iniciar sesión</Link>
                        <Link to="/register" className={`register ${activeButton === 'button2' ? 'active' : ''}`} onClick={() => handleActiveButton('button2')} >Crear cuenta</Link>
                    </div>
                    <SocialSignUpWrapper>
                        <FacebookLoginComponent currentRoute={currentRoute}/>
                        <GoogleLoginComponent currentRoute={currentRoute} />
                    </SocialSignUpWrapper>
                    <p className='separator'>- o -</p> 
                    <FormWrapper
                        onSubmit={handleSubmit}
                    >
                        <input 
                            type="email" 
                            placeholder="Correo"  
                            name="email"
                            value={formValues.email || ''}
                            onChange={handleChange}
                        />
                        { formErrors.email && <ErrorMessage>{formErrors.email}</ErrorMessage> }
                        <div className='password-container'>
                            <input 
                                type={isPasswordVisible ? 'text' : 'password'} 
                                placeholder='Contraseña' 
                                name="password"
                                value={formValues.password || ''}
                                onChange={handleChange}
                            />
                            <img src={OjoIcon} alt="icono ojo" onClick={() => handlePasswordVisible()} />
                        </div>
                        { formErrors.password && <ErrorMessage>{formErrors.password}</ErrorMessage> }
                        { pathname === '/register' && (
                            <>
                                <input 
                                    type="text" 
                                    placeholder='Nombre' 
                                    name="name"
                                    value={formValues.name || ''}
                                    onChange={handleChange}
                                />
                                { formErrors.name && <ErrorMessage>{formErrors.name}</ErrorMessage> }
                                <input 
                                    type="text" 
                                    placeholder='Apellido' 
                                    name="lastname"
                                    value={formValues.lastname || ''}
                                    onChange={handleChange}
                                />
                                { formErrors.lastname && <ErrorMessage>{formErrors.lastname}</ErrorMessage> }
                            </>
                        ) }
                        {
                            pathname === '/register' ? (
                                <p className='suscribirte' >Al suscribirte, estás aceptando nuestros <span>términos de servicio y política de privacidad.</span></p>
                            ) : (
                                <p className='olvide-password' >Olvidé mi contraseña</p>
                            )
                        }

                            <CustomButton primary type="submit" >
                                { pathname === '/register' ? "Registrarme" : "Iniciar sesión" }
                            </CustomButton>
                            { status === 'checking' && (
                                <SpinnerContainer>
                                    <Spinner />
                                </SpinnerContainer>
                            )
                            }
                            {errorMessage && <Alert alert={{ msg: errorMessage, error: true }} />}
                            {successMessage && <Alert alert={{ msg: successMessage, error: false }} />}
                    </FormWrapper>
                            { (!verified && userId > 0) &&
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 5 }}>
                                <Input onChange={e => setToken(e.target.value)} />
                                <p style={{ fontSize: '12px', margin: 0, cursor: 'pointer', textDecoration: 'underline', fontWeight: '200' }} onClick={generateNewCode} >Generar nuevo código</p>
                                <SendButtonCode primary onClick={handleSendCode} >
                                    Enviar código
                                </SendButtonCode>
                            </div> 
                            }
                </CardWrapper>
            </RightSide>
        </Container>
    )
}

export default AuthPage