import { useState, useEffect } from 'react'
import FacebookLogin from 'react-facebook-login'
import { FaFacebook } from 'react-icons/fa'

import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from '../redux/userDucks'

const FacebookLoginComponent = ({currentRoute}) => {

    const [buttonText, setButtonText] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const responseFacebook = (response) => {
        console.log(response)
        const obj = {
            mail: response.email,
            userId: response.userID,
            userName: response.name.split(' ')[0],
            userLastName: response.name.split(' ')[1],
            profilePhoto: response.picture.data.url,
            membership: false,
            telephone: null,
            successMessage: 'Inicio de sesión correcto'
        }
        dispatch(login(obj))
        navigate('/inicio')
    }

    // en desarrollo
    const appId = '484648007156167'

    // en despliegue
    // const appId = '6246147652132974'

    useEffect(() => {
        if(currentRoute === 'login'){
            setButtonText('Continuar con Facebook')
            return
        }
        setButtonText('Registrarme con Facebook')
    }, [currentRoute])

    return (
        <FacebookLogin 
            appId={appId}
            fields="name, email, picture"
            autoLoad={false}
            callback={responseFacebook}
            icon={<FaFacebook />}
            textButton={buttonText}
        />
    )
}

export default FacebookLoginComponent